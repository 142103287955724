import React from 'react'
import styles from './index.module.css'
import {DefaultContainer} from "../../Container";

export const Section7 = (props: any) => {


    return <DefaultContainer>
        <div id={"contactBlock"} className={styles.container}>
            <h2 data-class="sectionTitle">Остались вопросы?</h2>
            <div className={styles.top}> Мы знаем ответы на частые вопросы, если не нашли ответ – <br /><a href={"mailto:info.tbplatofrm@mail.ru"} className={styles.link}>просто напишите нам</a></div>

            <div className={styles.list}>
                    <div className={styles.item}><h5>Безопасно ли это?</h5> <p className={styles.text}>Все операции защищены виртуальной инфраструктурой в соответствии с требованиями стандарта PCI DSS, также используется дополнительная защита банковских операций пин-кодом или sms сообщением по
                        мировым стандартам безопасности МИР, VISA, MasterCard</p></div>
                    <div className={styles.item}><h5>Где вы храните данные?</h5> <p className={styles.text}>Мы — облачный сервис, поэтому наши сервера, в соответствии с законодательством РФ, расположены на российских мощностях Yandex Cloud </p></div>
                    <div className={styles.item}><h5>Есть ли у вас лицензия?</h5> <p className={styles.text}>У нас нет собственной банковской лицензии, мы финтех компания и работаем по лицензии банков-партнеров</p></div>
                </div>

        </div>
    </DefaultContainer>
}
