import React, {useState} from 'react';
import {Header} from "./components/Sections/Header";
import {Section1} from "./components/Sections/Section1";
import {Section2} from "./components/Sections/Section2";
import {Section3} from "./components/Sections/Section3";
import {Section4} from "./components/Sections/Section4";
import {Section5} from "./components/Sections/Section5";
import {Section6} from "./components/Sections/Section6";
import {Section7} from "./components/Sections/Section7";
import {Footer} from "./components/Sections/Footer";
import {ChatGPT} from "./components/Sections/ChatGPT";

function App() {

    const [contactOpen, setContactOpen] = useState<boolean>(false)
    return (
        <div>
            <Header setOpen={setContactOpen} />
            <Section1 setOpen={setContactOpen}/>
            <Section2 />
            <Section3 />
            <Section4 setOpen={setContactOpen}/>
            <Section5 />
            <Section6 />
            <ChatGPT />
            <Section7 setOpen={setContactOpen}/>
            <Footer />
        </div>
    );
}

export default App;
