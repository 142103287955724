import React from 'react'
import buttonStyles from '../../UI/Button/index.module.css'
import styles from './index.module.css'
import { DefaultContainer } from "../../Container";
import { Icons } from "../../../assets/icons";

export const ChatGPT = () => {
    return <DefaultContainer>
        <div className={styles.container}>
            <div className={styles.logo}>
                <Icons.LogoFintech />
            </div>
            <h2 className={styles.title}>ChatGPT и нейросети: прикладные инструменты для создания конкурентного преимущества и эффективной цифровой трансформации бизнеса</h2>
            <section className={styles.section}>
                <h3>Когда:</h3>
                <p><time dateTime="2023-04-20 18:30">20 апреля</time></p>
            </section>
            <section className={styles.section}>
                <h3>Время:</h3>
                <p><time dateTime="2023-04-20 18:30">18:30</time></p>
            </section>
            <section className={styles.section}>
                <h3>Темы к обсуждению:</h3>
                <ul>
                    <li>ChatGPT: инновационные возможности технологии нейросетей и их перспективы</li>
                    <li>Конкурентные преимущества и возможные риски использования ChatGPT и нейросетей в бизнесе</li>
                    <li>Прикладные инструменты и возможности применения ChatGPT и нейросетей в различные сферы бизнеса</li>
                </ul>
            </section>
            <div className={styles.action}>
                <a href={'https://t.me/Fintech_OIS_Bot'} target={"_blank"} className={`${buttonStyles.button} ${styles.actionButton}`}>Зарегистрироваться</a>
            </div>
        </div>
    </DefaultContainer>
}
