import React from 'react'
import styles from './index.module.css'
import {DefaultContainer} from "../../Container";

const data = [
    {
        title: "Гарантированная сделка",
        text: "Холдирование средств для маркетплейсов, р2р-площадок, посредников. Партнеры получают комиссию за выполненные работы, а клиенты подтверждают успешную сделку"
    },
    {
        title: "Чат-бот системы",
        text: "Реализуем чат-бот системы для оптимизации бизнес-процессов и взаимодействия с клиентами"
    },
    {title: "Удаленная идентификация", text: "Проводим удаленную идентификацию клиента, выпускаем виртуальные карты"},
    {
        title: "Кобрендовые банковские карты",
        text: "Эмитируем кобрендинговые, виртуальные и пластиковые банковские карты"
    },
    {title: "Биллинг баллов", text: "Поддерживаем биллинг баллов программ лояльности с партнерскими сетями"},
    {title: "Сервисы для самозанятых", text: "Регистрация, автоматизированное отчисление налогов в ФНС, отчетность"},
    {title: "Работа с банками", text: "Открываем счета и настраиваем транзакционный обмен и документооборот"},
    {
        title: "Рекуррентные платежи",
        text: "Привязка карты клиента, автоматизация списания комиссий и регулярных платежей"
    },
    {title: "Программы лояльности", text: "Разрабатываем, внедряем и обслуживаем программы лояльности"},
    {title: "Электронный кошелек", text: "Предоставляем сервис «Электронные кошельки»"},
    {title: "Прием платежей", text: "По выгодным ставкам, предлагаем альтернативу интернет-эквайрингу"},
    {title: "Банковская логистика", text: "Организуем доставку банковских продуктов с онлайн идентификацией"},
]

export const Section4 = (props: any) => {

    function open() {
        let element = document.getElementById('footer')

        // window.scrollBy({top : element?.offsetTop})

        // @ts-ignore
        document.getElementById("contactBlock").scrollIntoView({      behavior: 'smooth',      block: 'start'    })
    }

    return <DefaultContainer className={styles.bg}>
        <div className={styles.container}>
            <h2 data-class="sectionTitle">Услуги</h2>

            <div className={styles.list}>
                {data.map((item, index) => <div key={index} className={styles.item}>
                    <h3 className={styles.item_title}>{item.title}</h3>
                    <p className={styles.item_text}>{item.text}</p>
                    <p className={styles.link} onClick={open}>Заказать услугу</p>

                </div>)}
            </div>
        </div>
    </DefaultContainer>
}