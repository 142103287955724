import React, {FC, HTMLAttributes} from 'react'
import styles from './index.module.css'
import classNames from "classnames";

type propsTypes = {

} & HTMLAttributes<HTMLDivElement>

export const DefaultContainer:FC<propsTypes> = (props) => {


    return <div className={classNames(styles.container, {
        [`${props.className}`] : props.className
    })}>
        <div className={styles.body}>
        {props.children}
        </div>
    </div>
}