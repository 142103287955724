import React, {Dispatch} from 'react'
import styles from './index.module.css'
import {DefaultContainer} from "../../Container";
import {Button} from "../../UI/Button";
import { Icons } from '../../../assets/icons';

export const Section1 = ({setOpen} : {setOpen: Dispatch<boolean>}) => {


    function open() {
        let element = document.getElementById('footer')

        // window.scrollBy({top : element?.offsetTop})

        // @ts-ignore
        document.getElementById("contactBlock").scrollIntoView({      behavior: 'smooth',      block: 'start'    })
    }

    return <DefaultContainer className={styles.bg}>
        <div className={styles.container}>
            <div className={styles.info}>
                <div>
                    <h2 className={styles.title}>Финтех-платформа финансовых решений для бизнеса</h2>
                    <p className={styles.text}>Выстраиваем банковскую инфраструктуру под ваши бизнес-процессы</p>
                </div>
                <Button onClick={open} className={styles.button}>Связаться с нами</Button>
            </div>
            <div className={styles.picture}><Icons.Computer className={styles.computer}/></div>
            <Button onClick={open} className={styles.button_mobile}>Связаться с нами</Button>
        </div>
    </DefaultContainer>
}