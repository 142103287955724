import React from 'react'
import styles from './index.module.css'
import {DefaultContainer} from "../../Container";


export const Footer = () => {


    return <DefaultContainer className={styles.bg}>
        <div id={"footer"} className={styles.container}>
            <div className={styles.left}>
                <div className={styles.top}>
                    <p className={styles.text}>
                        ООО "Открытые интеграционные сервисы"
                    </p>
                    <p className={styles.text}>
                        109028, Москва г, Серебряническая наб., дом № 29, этаж 8, помещение II, комната 68
                    </p>
                    <p className={styles.text}>
                        ИНН 9709074908
                    </p>
                    <p className={styles.text}>
                        ОГРН 1217700471086
                    </p>
                </div>
                <div className={styles.bottom}>
                    <h5 className={styles.text}>Лицензии банков партнеров:</h5>
                    <p className={styles.text}>
                        ПАО "ТКБ БАНК" - Лицензия ЦБ РФ № 2210 <a className={styles.link} target={"_blank"} href="https://www.tkbbank.ru/documents/general_license/">по ссылке</a>.
                    </p>
                    <p className={styles.text}>ООО "ОИС" предоставляет сервис в соответствии с <a className={styles.link} target={"_blank"} href="https://www.tkbbank.ru/documents/edbo-35/">Единым договором банковского обслуживания</a> ТКБ Банк ПАО.</p>
                    <p className={styles.text}>Информация о БПА и Банке <a className={styles.link} target={"_blank"} href="https://storage.yandexcloud.net/tb-licenses/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F%20%D0%BE%20%D0%91%D0%B0%D0%BD%D0%BA%D0%BE%D0%B2%D1%81%D0%BA%D0%BE%D0%BC%20%D0%BF%D0%BB%D0%B0%D1%82%D0%B5%D0%B6%D0%BD%D0%BE%D0%BC%20%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D0%B5%20(%D0%91%D0%9F%D0%90)%20%20%D0%B8%20%D0%91%D0%B0%D0%BD%D0%BA%D0%B5%20v2.pdf">по ссылке</a>.</p>
                    <p className={styles.text}><a className={styles.link} target={"_blank"} href="https://storage.yandexcloud.net/tb-licenses/%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8_%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4%D0%BE%D0%B2_%D0%B4%D0%B5%D0%BD%D0%B5%D0%B6%D0%BD%D1%8B%D1%85_%D1%81%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B2.pdf">Условия организации переводов денежных средств</a> посредством Процессингового центра ООО "Открытые интеграционные сервисы"</p>
                    <p className={styles.text + ' ' + styles.smartbot}>Смартбот - ваш помощник в управлении личными финансами. Финансовый помощник в мессенджере, который поможет легко управлять бюджетом и закрыть обязательства</p>
                </div>
            </div>
            <div className={styles.links}>
                <p className={styles.text}>Подробную информацию о тарифах можно посмотреть <a className={styles.link} target={"_blank"} href="https://storage.yandexcloud.net/tb-licenses/%D0%A2%D0%B0%D1%80%D0%B8%D1%84%D1%8B%20Fintech%20Platfrom%20v2.pdf">здесь</a></p>
            {/*    <a className={styles.link} target={"_blank"} href="https://drive.google.com/file/d/1Rjk8AiA6QyhrfeT4jviqosY11ucVIHg_/view?usp=sharing">Условия организации переводов денежных средств посредством Процессингового Центра</a>*/}
            {/*    <a className={styles.link} target={"_blank"} href="https://www.tkbbank.ru/private/services/operation/edbo/">Единый договор банковского обслуживания</a>*/}
            {/*    <a className={styles.link} target={"_blank"} href="https://www.tkbbank.ru/private/cards/prepaid/">Тарифы ТКБ БАНК ПАО</a>*/}
            {/*    <a className={styles.link} target={"_blank"} href="https://drive.google.com/file/d/1Ck8JiuptqwMbf6ceNThwWMaq_W5XZvRI/view?usp=sharing">Информация для клиентов по Договору БПА № 3251</a>*/}
            </div>
        </div>
    </DefaultContainer>


}
