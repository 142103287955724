import React, {FC, HTMLAttributes} from 'react'
import styles from './index.module.css'
import classNames from "classnames";

type ButtonType = {
    disabled?: boolean
} & HTMLAttributes<HTMLButtonElement>

export const Button:FC<ButtonType> = (props) => {

    const { className, ...other } = props



    return <button className={classNames(styles.button , {
        [`${props.className}`] : props.className,
        [styles.disabled] : props.disabled
    })}{...other}>
        {props.children}
    </button>
}
