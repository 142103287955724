import React from 'react'
import styles from './index.module.css'
import {DefaultContainer} from "../../Container";

export const Section3 = () => {

    return <DefaultContainer className={styles.bg}>
        <div className={styles.container}>
            <h2 data-class="sectionTitle">Bank-as-a-Service для бизнеса</h2>
            <p className={styles.text}>Наша банковская IT-платформа предоставляет партнеру возможность реализовать любые финансовые сервисы для своих клиентов с помощью наших IT-методов</p>
        </div>
    </DefaultContainer>
}



