import React from 'react'
import styles from './index.module.css'
import {DefaultContainer} from "../../Container";

const data = ["Расскажите о своем бизнесе", "Получите предложение" ,"Подпишите договор" , "Подключите сервисы по API"]

export const Section6 = () => {


    return <DefaultContainer>
        <div className={styles.container}>
            <h2  data-class="sectionTitle">Схема рабочего процесса</h2>
            <div className={styles.list}>
                {data.map((item ,index) => <div key={index} className={styles.item}>
                    <p className={styles.index}>{index + 1}</p><div className={styles.right}><p className={styles.stage}>этап</p><p className={styles.text}>{item}</p></div>
                </div>)}
            </div>
        </div>
    </DefaultContainer>
}