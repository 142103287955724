import React, {Dispatch} from 'react'
import styles from './index.module.css'
import {DefaultContainer} from "../../Container";
import { Button } from '../../UI/Button';
import { Icons } from '../../../assets/icons';

export const Header = ({setOpen} : {setOpen: Dispatch<boolean>}) => {

    function open() {
        let element = document.getElementById('footer')

        // window.scrollBy({top : element?.offsetTop})

        // @ts-ignore
        document.getElementById("contactBlock").scrollIntoView({      behavior: 'smooth',      block: 'start'    })
    }

    return <DefaultContainer className={styles.bg}>
        <div className={styles.container}>

            <div className={styles.left}>
                <Icons.Logo className={styles.icon}/>
                <h1 className={styles.title}>Финтех-платформа </h1>
            </div>
            <Button onClick={open} className={styles.button}>Связаться с нами</Button>

        </div>
    </DefaultContainer>
}
