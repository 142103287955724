import React from 'react'
import styles from './index.module.css'
import {DefaultContainer} from "../../Container";



export const Section5 = () => {


    return <DefaultContainer>

        <div className={styles.container}>
            <h2  data-class="sectionTitle">Банк как услуга (Bank-as-a-Service)</h2>

            <div className={styles.list}>
                <div className={styles.item}>API четырех банков</div>
                <div className={styles.item}>Виртуальные, пластиковые и кобрендинговые карты</div>
                <div className={styles.item}>Р2Р переводы и эквайринг</div>
            </div>
        </div>
    </DefaultContainer>
}