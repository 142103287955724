import React from 'react'
import styles from './index.module.css'
import {DefaultContainer} from "../../Container";

const data = ["Маркетплейс" , "Р2Р площадкам" , "Ритейлу" ,"Банкам", "Финтеху"]

export const Section2 = () => {

    return <DefaultContainer className={styles.bg}>

        <div className={styles.container}>

            <h2 data-class="sectionTitle">Для кого</h2>
            <div className={styles.list}>
                {data.map((item ,index) => <div className={styles.item} key={index}><span className={styles.circle}/>{item}</div>)}
            </div>
        </div>
    </DefaultContainer>
}